/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.line-profile-card::v-deep {
  padding: 16px 12px;
  margin: 0 auto;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  max-width: 400px;
  background: #00C300;
  color: #fff;
  border-radius: 4px;
}
.line-profile-card::v-deep img {
  border: 2px solid #fff;
}
